const loader = /*html*/ `
<div
  id="appLoader"
  class="w-screen h-screen flex justify-center items-center backdrop-blur-sm bg-white/60 fixed z-50"
>
  <div class="lds-spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
`;

export const toggleAppLoader = () => {
  const appLoader = document.getElementById('appLoader');
  if (typeof appLoader != 'undefined' && appLoader != null) {
    appLoader.remove();
  } else {
    document.body.insertAdjacentHTML('afterbegin', loader);
  }
};
